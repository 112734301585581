import { api } from './api';

export async function DashboardTokens(token) {
    try {
        let baseURL = '/paygo/dashboard';
        const response = await api.get(baseURL, {
            headers: {
                'x-access-token': token,
                "ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
