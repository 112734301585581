/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "react-autocomplete";
import { ListUsers } from "services/users";
import { DeleteHash } from "services/tokens";
import { ListUniqueTokens } from "services/tokens";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { ListTokensForType } from "services/tokens";
import { PagseguroAvulso } from "services/pagseguro";

export default function Tokens() {
    const { user } = useAuthContext();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const bgItem = useColorModeValue(
      { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
      { bg: "navy.700", boxShadow: "unset" }
    );

    const [listHash, setListHash] = useState([]);
    const [totalPagesHash, setTotalPagesHash] = useState(1);
    const [currentPageHash, setCurrentPageHash] = useState(1);
    const [searchTxtHash, setSearchTxtHash] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [usersAutoComplete, setUsersAutoComplete] = useState('');
    const [usersSearch, setUsersSearch] = useState('');
    const [listUsers, setListUsers] = useState([]);
    const [usersAutoCompleteModal, setUsersAutoCompleteModal] = useState('');
    const [usersSearchModal, setUsersSearchModal] = useState('');
    const [listUsersModal, setListUsersModal] = useState([]);
    const [tokensAutoComplete, setTokensAutoComplete] = useState('');
    const [tokensSearch, setTokensSearch] = useState('');
    const [listTokens, setListTokens] = useState([]);
    const [qtd, setQtd] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalDeleteHash, setModalDeleteHash] = useState(false);
    const [itemDeleteHash, setItemDeleteHash] = useState(null);

    useEffect(() => {
      // setListHash([]);
      // setTotalPagesHash(1);
      const timeOutId = setTimeout(() => {
          getListUnique();
      }, 500);
      return () => clearTimeout(timeOutId);
    }, [currentPageHash, searchTxtHash, usersAutoComplete, filterDate]);
  
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListUsers();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [usersSearch]);

    useEffect(() => {
      const timeOutId = setTimeout(() => {
          getListUsersModal();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [usersSearchModal]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListTokens();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [tokensSearch]);

    const handlePageChangeHash = (pageNumber) => {
        setCurrentPageHash(pageNumber);
    };

    const getListUnique = async () => {
        const response = await ListUniqueTokens(usersAutoComplete, filterDate, searchTxtHash, currentPageHash, user?.token);
        setListHash(response?.result?.list || []);
        setTotalPagesHash(response?.result?.qtdPages || 1);
    };

    const getListUsers = async () => {
        const response = await ListUsers(null, usersSearch, 1, 5, user?.token);
        setListUsers(response?.result?.list || []);
    };

    const getListUsersModal = async () => {
      const response = await ListUsers(null, usersSearchModal, 1, 5, user?.token);
      setListUsersModal(response?.result?.list || []);
    };

    const getListTokens = async () => {
        const response = await ListTokensForType(null, user?.user?.id, tokensSearch, 1, 'adm', 5, user?.token);
        setListTokens(response?.result?.list || []);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onCloseHash = () => {
        setModalDeleteHash(false);
        setItemDeleteHash(null);
    };
    
    const handleClickToken = async () => {
        if (usersAutoCompleteModal && tokensAutoComplete && qtd) {
            try {
                setIsLoading(true);
                const response = await PagseguroAvulso(
                    Number(tokensAutoComplete),
                    Number(usersAutoCompleteModal),
                    Number(qtd),
                    user?.token,
                );
                setIsLoading(false);
                if (response?.status === 200 || response?.status === 201) {
                    toast(response?.message || 'Token adicionado com sucesso!');
                    setTokensAutoComplete('');
                    setUsersAutoCompleteModal('');
                    setQtd('');
                    setTokensSearch('');
                    setUsersSearchModal('');
                    onClose();
                    if (usersAutoComplete === usersAutoCompleteModal) {
                      getListUnique();
                    }
                } else {
                    toast(response?.message);
                }
            } catch (err) {
                setIsLoading(false);
                toast(err.message);
            }
        } else {
            toast('Preencha todos os campos');
        }
    };

    const ApplyDeleteHash = async () => {
        try {
            setIsLoading(true);
            const response = await DeleteHash(
                itemDeleteHash?.id,
                user?.token,
            );
            setIsLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                toast(response?.message || 'Hash removida com sucesso!');
                onCloseHash();
                getListUnique();
            } else {
                toast(response?.message);
            }
        } catch (err) {
            setIsLoading(false);
            toast(err.message);
        }
    }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card p='0px 0px 20px 0px'>
          <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px" gap="10px">
              <SearchBar
                  placeholder="Buscar hash"
                  value={searchTxtHash}
                  setValue={setSearchTxtHash}
              />
              <Autocomplete
                  getItemValue={(item) => item.id}
                  items={listUsers}
                  renderItem={(item, isHighlighted) =>
                      <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                  }
                  renderInput={(props) => (
                      <Input
                          {...props}
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder='Selecione um usuário'
                          fontWeight='500'
                          size='lg'
                          value={usersSearch}
                          onChange={(e) => setUsersSearch(e.target.value)}
                      />
                  )}
                  onSelect={(val, item) => {
                      setUsersAutoComplete(val);
                      setUsersSearch(item.name);
                  }}
              />
              <Input
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  placeholder='Selecione uma data'
                  fontWeight='500'
                  size='lg'
                  type="date"
                  value={filterDate}
                  onChange={(event) => setFilterDate(event.target.value)}
                  maxW="200px"
              />
              <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
                Adicionar
              </Button>
          </Flex>
          <Flex
              direction='flex'
              justifyContent="space-between"
              w="90%"
              alignSelf="center"
              mb="10px"
          >
              {["DATA", "QTD", "VALOR DO ATIVO", "OPERAÇÃO", "ORIGEM", "USUÁRIO", "TOKEN", "TIPO", "EXCLUIR"]?.map((v, i) => (
                  <Text
                      color={textColor}
                      fontSize={{
                          base: "md",
                      }}
                      fontWeight='bold'
                      key={v}
                      w="11%"
                  >
                      {v}
                  </Text>
              ))}
          </Flex>
          {(listHash || []).map((v) => {
            const qtd = Math.max(v.token_qtd_order_current, v.token_qtd_order_old) - Math.min(v.token_qtd_order_current, v.token_qtd_order_old);
            return (
              <Card
                  _hover={bgItem}
                  bg='transparent'
                  boxShadow='unset'
                  px='0px'
                  // py='0px'
                  transition='0.2s linear'
                  style={{ cursor: 'pointer' }}
                  key={v.id}
                  w="90%"
                  alignSelf="center"
              >
                  <Flex
                      direction='flex'
                      justifyContent="space-between"
                      w="100%"
                      alignSelf="center"
                  >
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                          {lightFormat(new Date(v.created_at), 'dd/MM/yyyy HH:mm')}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                        {qtd}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                        {`R$ ${toPrice(v.token_value_unity * qtd, 2)}`}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                        {v.type === 'buy' ? 'Compra' : 'Venda'}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                        {v.platform === 'web' ? 'Plataforma' : 'Exchange'}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                          {v.users_name}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="14%"
                          minW="14%"
                      >
                          {v.tokens_name}
                      </Text>
                      <Text
                          color={textColor}
                          fontSize={{
                              base: "md",
                          }}
                          fontWeight='bold'
                          maxW="11%"
                          minW="11%"
                      >
                          {v.transactionId ? 'Pago' : 'Adicionado'}
                      </Text>
                      <Button
                        bg="red.600"
                        color="white"
                        onClick={() => {
                          setModalDeleteHash(true);
                          setItemDeleteHash(v);
                        }}
                        isLoading={isLoading}
                        w="19%"
                        fontSize={12}
                      >
                        Excluir token
                      </Button>
                  </Flex>
              </Card>
            );
          })}
          <Pagination
              currentPage={currentPageHash}
              totalPages={totalPagesHash}
              onPageChange={handlePageChangeHash}
              limit={30}
          />
          </Card>
        </Flex>
        <Modal isOpen={modalDeleteHash} onClose={onCloseHash} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    Remover hash
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Text color='secondaryGray.700' fontSize='medium' fontWeight='400'>Tem certeza que deseja remover permanentemente a hash selecionada?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant='outline'
                        w="150px"
                        marginRight="10px"
                        onClick={onCloseHash}
                    >
                        Fechar
                    </Button>
                    <Button
                        bg="red.600"
                        color="white"
                        w="150px"
                        onClick={ApplyDeleteHash}
                        isLoading={isLoading}
                    >
                        REMOVER
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Adicionar token
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para adicionar um token ao usuário`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Flex style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <FormControl style={{ maxWidth: '50%', marginBottom: 16 }}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'
                            >
                                Quantidade<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                placeholder='Quantidade'
                                type="number"
                                fontWeight='500'
                                size='lg'
                                value={qtd}
                                onChange={(txt) => {
                                    if (txt.target.value.length < 10) {
                                        setQtd(txt.target.value);
                                    }
                                }}
                            />
                        </FormControl>
                        <Grid templateColumns='repeat(2, 1fr)' gap='16px' mb="100px">
                            <FormControl>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='8px'
                                >
                                    Usuário<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Autocomplete
                                    getItemValue={(item) => item.id}
                                    items={listUsersModal}
                                    renderItem={(item, isHighlighted) =>
                                        <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                                    }
                                    renderInput={(props) => (
                                        <Input
                                            {...props}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Selecione um usuário'
                                            fontWeight='500'
                                            size='lg'
                                            value={usersSearchModal}
                                            onChange={(e) => setUsersSearchModal(e.target.value)}
                                        />
                                    )}
                                    onSelect={(val, item) => {
                                        setUsersAutoCompleteModal(val);
                                        setUsersSearchModal(item.name);
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='8px'
                                >
                                    Token<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Autocomplete
                                    getItemValue={(item) => item.id}
                                    items={listTokens}
                                    renderItem={(item, isHighlighted) =>
                                        <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                                    }
                                    renderInput={(props) => (
                                        <Input
                                            {...props}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Selecione um token'
                                            fontWeight='500'
                                            size='lg'
                                            value={tokensSearch}
                                            onChange={(e) => setTokensSearch(e.target.value)}
                                        />
                                    )}
                                    onSelect={(val, item) => {
                                        setTokensAutoComplete(val);
                                        setTokensSearch(item.name);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Button
                            variant='brand'
                            type="submit"
                            alignSelf="flex-end"
                            w="150px"
                            marginTop="24px"
                            onClick={handleClickToken}
                            isLoading={isLoading}
                        >
                            Adicionar token
                        </Button>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <ToastContainer />
    </Box>
  );
}
