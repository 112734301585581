/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box,
    // SimpleGrid,
} from "@chakra-ui/react";
// import DevelopmentTable from "views/admin/users/components/DevelopmentTable";
// import CheckTable from "views/admin/users/components/CheckTable";
import ColumnsTable from "views/admin/users/components/ColumnsTable";
// import ComplexTable from "views/admin/users/components/ComplexTable";
import {
//   columnsDataDevelopment,
//   columnsDataCheck,
  columnsDataColumns,
//   columnsDataComplex,
} from "views/admin/users/variables/columnsData";
// import tableDataDevelopment from "views/admin/users/variables/tableDataDevelopment.json";
// import tableDataCheck from "views/admin/users/variables/tableDataCheck.json";
// import tableDataColumns from "views/admin/users/variables/tableDataColumns.json";
// import tableDataComplex from "views/admin/users/variables/tableDataComplex.json";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListUsers } from "services/users";

export default function Users() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    const getList = async () => {
        const response = await ListUsers(null, searchTxt, currentPage, null, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ColumnsTable
            columnsData={columnsDataColumns}
            tableData={list}
            getList={getList}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchTxt={searchTxt}
            setSearchTxt={setSearchTxt}
        />
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </SimpleGrid> */}
    </Box>
  );
}
