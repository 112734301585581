/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
Flex,
FormControl,
FormErrorMessage,
FormLabel,
Grid,
Icon,
Image,
Input,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
Table,
Tbody,
Td,
Text,
Textarea,
Th,
Thead,
Tr,
useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from "react";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import Dropzone from "views/admin/profile/components/Dropzone";
import { MdDocumentScanner, MdEditDocument, MdFileCopy, MdFileOpen, MdGeneratingTokens } from "react-icons/md";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UpdateWithdraw } from "services/extracts";
import { getNameWithExtensionFromUrl } from "utils/masks";
import { moneyMask } from "utils/masks";
import { CreateProof } from "services/financy";
import { EditProof } from "services/financy";
import { DeleteProof } from "services/financy";
import { priceUnmask } from "utils/masks";
import Autocomplete from "react-autocomplete";
import { ListUsers } from "services/users";

export default function ColumnsTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const brandColor = useColorModeValue("brand.500", "white");
  // const bg = useColorModeValue("gray.100", "navy.700");
  // const borderColorArchive = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [description, setDescription] = useState('');
  const [proof, setProof] = useState('');
  const [valueProof, setValueProof] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [usersSearch, setUsersSearch] = useState('');
  const [usersAutoComplete, setUsersAutoComplete] = useState('');

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          getListUsers();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [usersSearch]);

  const getListUsers = async () => {
      const response = await ListUsers(null, usersSearch, 1, 5, user?.token);
      setListUsers(response?.result?.list || []);
  };

  const onClose = () => {
      setIsOpen(false);
      setInfoRow(null);
      setProof('');
      setDescription('');
      setValueProof('');
      setUsersAutoComplete('');
      setUsersSearch('');
  };

  const onClickDownload = (item) => {
    const fileName = getNameWithExtensionFromUrl(String(item.file));
    fetch(item.file).then((response) => {
        response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            const alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        });
    });
  };

  const onClickEdit = (item) => {
    setProof(item.file || '');
    setDescription(item.description || '');
    setValueProof(`R$ ${toPrice(item.value || '', 2)}`);
    setUsersAutoComplete(item.id_user || '');
    setUsersSearch(item.user_name || '');
    setInfoRow(item);
    setIsOpen(true);
  };

  const onClickDelete = async (item) => {
      await DeleteProof(item.id, user?.token);
      getList();
      onClose();
  };

  const onSaveBanner = async () => {
    try {
      setIsLoading(true);
      const response = !infoRow?.id ? await CreateProof(proof, priceUnmask(valueProof), description, usersAutoComplete, user?.token) : await EditProof(proof, priceUnmask(valueProof), description, usersAutoComplete, infoRow?.id, user?.token);
      setIsLoading(false);
      if (response?.status === 200 || response?.status === 201) {
          toast(response?.message || 'Comprovante salvo com sucesso!');
          onClose();
          getList();
      } else {
        toast('Ocorreu um erro salvar comprovante!');
      }
    } catch (error) {
      toast('Ocorreu um erro salvar comprovante!');
    }
  }

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex justifyContent="flex-end" padding="0px 20px 0px 20px" mb="20px">
        {user?.user?.typeUser === 'managers' ? (
          <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
              Criar comprovante
          </Button>
        ) : null}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  {infoRow?.id ? 'Editar comprovante' : 'Criar comprovante'}
                  <Text color='secondaryGray.700' fontSize='xs' fontWeght='400'>{`Preencha as informações abaixo para ${infoRow?.id ? 'editar' : 'criar'} um comprovante`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <Dropzone
                      minH="60px"
                      w="100%"
                      borderRadius="10px"
                      mb="24px"
                      onImageSelect={(file) => setProof(file[0] || '')}
                      multiple={false}
                      accept="image/png, image/jpeg, image/jpg"
                      content={
                          <Box display="flex" flexDirection="column" gap="10px">
                              {proof.length > 0 ? (
                                  <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(proof))}</Text>
                              ) : (
                                  <>
                                      <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar o COMPROVANTE.</Text>
                                      <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG</Text>
                                  </>
                              )}
                          </Box>
                      }
                  />
                  <FormControl>
                      <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'
                      >
                          Descrição do comprovante
                      </FormLabel>
                      <Textarea
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder='Descrição'
                          fontWeight='500'
                          size='lg'
                          borderWidth="1px"
                          resize="none"
                          borderRadius="20px"
                          minH="150px"
                          css={{
                              '::placeholder': {
                                  color: '#A3AED0',
                                  fontWeight: 400,
                              }
                          }}
                          mb="20px"
                          value={description}
                          onChange={(txt) => {
                            const value = txt.target.value;
                            setDescription(value);
                          }}
                      />
                  </FormControl>
                  <Grid templateColumns='repeat(2, 1fr)' gap='16px' mb="100px" w="100%">
                    <FormControl>
                        <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'
                        >
                            Valor
                        </FormLabel>
                        <Input
                            variant='auth'
                            fontSize='sm'
                            ms={{ base: "0px", md: "0px" }}
                            placeholder='Valor'
                            fontWeight='500'
                            size='lg'
                            value={valueProof}
                            onChange={(txt) => {
                                const formatMoney = moneyMask(txt.target.value);
                                setValueProof(formatMoney);
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'
                        >
                            Usuário<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Autocomplete
                            getItemValue={(item) => item.id}
                            items={listUsers}
                            renderItem={(item, isHighlighted) =>
                                <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                            }
                            wrapperStyle={{ width: '100%' }}
                            renderInput={(props) => (
                                <Input
                                    {...props}
                                    variant='auth'
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    placeholder='Selecione um usuário'
                                    fontWeight='500'
                                    size='lg'
                                    value={usersSearch}
                                    minW="100%"
                                    onChange={(e) => setUsersSearch(e.target.value)}
                                />
                            )}
                            onSelect={(val, item) => {
                                setUsersAutoComplete(val);
                                setUsersSearch(item.name);
                            }}
                        />
                    </FormControl>
                  </Grid>
                  <Button
                      variant='brand'
                      alignSelf="flex-end"
                      w="200px"
                      marginTop="24px"
                      onClick={onSaveBanner}
                      isLoading={isLoading}
                  >
                      {infoRow?.id ? 'Editar comprovante' : 'Criar comprovante'}
                  </Button>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "AÇÕES") {
                  return (
                      <Flex align='center' pl="30px" pt="5px">
                        <Menu
                          row={cell.row.original}
                          onClickDownload={onClickDownload}
                          onClickEdit={user?.user?.typeUser === 'managers' ? onClickEdit : null}
                          onClickDelete={user?.user?.typeUser === 'managers' ? onClickDelete : null}
                        />
                      </Flex>
                  )
                } else if (cell.column.Header === "DESCRIÇÃO") {
                  data = (
                    <Flex align='center' gap={1}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "NOME DO USUÁRIO") {
                  data = (
                    <Flex align='center' gap={1}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "VALOR") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {`R$ ${toPrice(cell.value, 2)}`}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "CRIADO EM") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
